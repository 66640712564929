<template>
<div>
     <v-card
        class="mx-auto mt-5 pb-3 px-10"
        width="75%"
    >
    <v-card-text>
        <form class="mt-2">
            <v-text-field
                outlined
                dense
                v-model="email"
                :error-messages="emailErrors"
                label="E-mail"
                required
            ></v-text-field>
            <v-text-field
                outlined
                dense
                v-model="display_name"
                :error-messages="nameErrors"
                :counter="30"
                label="Uživateľské meno"
                hint="Toto meno sa používa v tabuľke"
                persistent-hint
                required
            ></v-text-field>

            <v-row>
                <v-col cols="6">
                    <v-text-field
                        outlined
                        dense
                        v-model="first_name"
                        label="Krstné meno"
                    ></v-text-field>
                </v-col>
                    
                <v-col>
                    <v-text-field
                        outlined
                        dense
                        v-model="last_name"
                        label="Priezvisko"
                    ></v-text-field>
                </v-col>

            </v-row>
            
            <v-select
                outlined
                dense
                v-model="select"
                :items="items"
                :error-messages="selectErrors"
                :menu-props="{offsetY: true}"
                label="Rola"
                required
            ></v-select>
        </form>
    </v-card-text>

    <v-card-actions>
        <v-spacer />
        <v-btn 
            color="indigo"
            @click="submit"
            large
            outlined
            class="mr-2"
            >
            Vytvoriť nový účet
        </v-btn>
    </v-card-actions>

    

  </v-card>

  <v-snackbar
        v-model="snackbar"
        top
        centered
        :color="snackbar_color"
        
      >
      {{ snackbar_msg }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snackbar_color"
          depressed
          v-bind="attrs"
          @click="snackbar = false"
        >
          Zatvoriť
        </v-btn>
      </template>

  </v-snackbar>

  <v-overlay :value="overlay" >
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
  </v-overlay>

</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import axios from 'axios'

import { register } from '@/data/api'

export default {
    name: "CreateAccount",

    mixins: [validationMixin],

    validations: {
      display_name: { required, maxLength: maxLength(30) },
      email: { required, email },
      select: { required },
    },

    data: () => ({
      email: '',
      display_name: '',
      first_name: '',
      last_name: '', 

      select: null,
      items: [
        'Recepcia',
        'Technik'
      ],

      snackbar: false,
      snackbar_msg: "",
      snackbar_color: "success",
      overlay: false,
    }),

    computed: {
      selectErrors () {
        const errors = []
        if (!this.$v.select.$dirty) return errors
        !this.$v.select.required && errors.push('Rola je povinná')
        return errors
      },
      nameErrors () {
        const errors = []
        if (!this.$v.display_name.$dirty) return errors
        !this.$v.display_name.maxLength && errors.push('Uživateľské meno prekročilo povolený limit 30 znakov')
        !this.$v.display_name.required && errors.push('Uživateľské meno je povinné')
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('E-mail musí byť platný')
        !this.$v.email.required && errors.push('E-mail je povinný')
        return errors
      },
    },

    methods: {
      clearInputs(){
        this.$v.$reset()
        this.email = ''
        this.display_name = ''
        this.first_name = ''
        this.last_name = '',
        this.select = null
      },

      async submit () {
        this.$v.$touch()
        
        // if valid
        if(!this.$v.$invalid){
            this.overlay = true
            let group = ""
            if (this.select === "Technik"){
              group = "basic"
            }else if(this.select === "Recepcia"){
              group = "editor"
            }


            await axios.post(register(),
              {
                  email: this.email,
                  display_name: this.display_name,
                  first_name: this.first_name,
                  last_name: this.last_name,
                  group: group
              },
              {
                  headers:{
                      "Authorization" : "Token " + this.$store.getters.token,
                      'Content-Type' : 'application/json',
                  },
              },  
          ).then(() => {
              this.snackbar_msg = "Účet bol úspešne vytvorený."
              this.snackbar_color = "success"
              this.snackbar = true

              this.clearInputs()

          }).catch(error => {
              if(error.response.data.email){
                this.snackbar_msg = "Účet s týmto e-mailom už existuje."

              }else if(error.response.data['display_name']){
                this.snackbar_msg = "Účet s týmto uživateľským menom už existuje."
              }else{
                this.snackbar_msg = "Vyskytla sa neočakávaná chyba."
              }
              this.snackbar_color = "error"
              this.snackbar = true  
          })
          this.overlay = false
        }
      },
    },

    deactivated(){
        this.clearInputs()
    }
}
</script>